import { InternalError,NotFound } from '@islands/components';
import { RdcAppShell } from '@moveinc/rdc-app-shell';

export const Layout = ({
  children,
  appShellProps = {},
  statusCode,
  logger = () => console,
}: any) => {
  return (
    <RdcAppShell {...appShellProps} logger={logger('RdcAppShell')}>
      {statusCode === 404 ? <NotFound /> : statusCode === 500 ? <InternalError /> : children}
    </RdcAppShell>
  );
};

export default Layout;
