/* eslint-disable */
const NodeCache = require('node-cache');

import { logger } from './logger';

const DEFAULT_TTL = 86400; //seconds

const xWebCache = new NodeCache();

export function setItem(key, val, TTL = DEFAULT_TTL) {
  if (!xWebCache) {
    return;
  }
  xWebCache.set(key, val, TTL);
}

export function getItem(key) {
  if (!xWebCache) {
    return null;
  }
  try {
    const cachedData = xWebCache.get(key);
    if (cachedData) {
      return cachedData;
    }
  } catch (e) {
    logger.error(e.message, e);
    return null;
  }
}

export function deleteItem(key) {
  if (!xWebCache) {
    return;
  }
  if (!key) {
    throw new Error('Key not provided to delete');
  }

  xWebCache.del(key.toString());
}
