import { createContext, useContext } from 'react';

export const DashboardContext = createContext({});

export const useDashboardState = () => {
  const state = useContext(DashboardContext);
  return state;
};

export const DashboardContextProvider = ({ children, value }) => {
  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};
