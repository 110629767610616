const common = require('./common.json');
const getConfig = require('next/config').default;
const deepmerge = require('deepmerge');

const { publicRuntimeConfig: { awsAccount, configEnv, cdnEnv, assetPrefix } = {} } =
  getConfig() || {};

const env = awsAccount || process.env.AWS_ACCOUNT || 'development';
const confEnv = configEnv || env;

let envConfig;
try {
  envConfig = require(`./${confEnv}.json`);
} catch (e) {
  console.warn(`No config file found for environment: ${confEnv}, loading development.json`);
  envConfig = require('./development.json');
}

module.exports = { ...deepmerge(common, envConfig), env, configEnv: confEnv, cdnEnv, assetPrefix };
