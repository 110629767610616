import React, { createContext, useContext } from 'react';

type IslandContextProps = {
  config: object;
  children: React.ReactNode;
};

const IslandContext = createContext<any>({ config: {} });

export const useIslandContext = () => useContext(IslandContext);

export const IslandContextProvider = ({ children, config }: IslandContextProps) => {
  return <IslandContext.Provider value={{ config }}>{children}</IslandContext.Provider>;
};

export default IslandContextProvider;
