import config from '../../../src/config';

export const comscoreScript = `
  window.ignoreNextComscorePixelForPage = window.location.origin + window.location.pathname;
  var cookie, doc;
  var shareData = navigator.doNotTrack === '1' || navigator.globalPrivacyControl === true || /ccpaoptout=true/.test(document.cookie) ? 0 : 1;
  var tag = {
    cs_fpid: ((cookie = document.cookie.split(';').filter(function (c) {
      return c.trim().startsWith('__vst=');
    })) === null || cookie === void 0 || (cookie = cookie.at(0)) === null || cookie === void 0 || (cookie = cookie.split('=')) === null || cookie === void 0 || (cookie = cookie.at(1)) === null || cookie === void 0 ? void 0 : cookie.trim()) || new URLSearchParams(new URL(window.location.href).search).get('iFrame_edwVisitorId') || null,
    cs_ucfr: shareData,
    cs_fpit: 'c',
    cs_fpdm: '*null',
    cs_fpdt: '*null',
    c1: '2',
    c2: '6036093'
    ${config.comscoreOptions !== undefined ? `, options: ${JSON.stringify(config.comscoreOptions)}` : ''}
  };
  window._comscore = window._comscore || [];
  window._comscore.push(tag);
`;
