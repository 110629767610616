import React from 'react';
import Head from 'next/head';

import { AdsLibrary } from '@moveinc/rdc-ads-media';

interface AdsConfigProps {
  hoistAdsLibraryProps?: {
    pageType: string;
    prebid_stage?: string;
    supadslib?: string;
    noLazyLoading?: boolean;
    tag?: string;
  };
}

export const AdsConfig = ({ hoistAdsLibraryProps }: AdsConfigProps) => {
  if (!hoistAdsLibraryProps) {
    return null;
  }

  return (
    <Head>
      <AdsLibrary key="AdsLibrary" {...hoistAdsLibraryProps} />
    </Head>
  );
};

export default AdsConfig;
