import {
  Container,
  TabContent,
  TabContents,
  TabItem,
  TabItems,
  TabsContainer,
  TypeDisplayMedium,
} from '@moveinc/rdc-ui';
import dynamic from 'next/dynamic';
import React from 'react';

import { DashboardContextProvider } from './Context';

const tabs = [
  {
    label: 'MFEs',
    component: dynamic(() => import('./MFEs').then((mod) => mod.MFEs)),
    route: 'mfes',
  },
  {
    label: 'Health',
    component: dynamic(() => import('./Health').then((mod) => mod.Health)),
    route: 'health',
  },
  {
    label: 'Config',
    component: dynamic(() => import('./AppConfig').then((mod) => mod.AppConfig)),
    route: 'config',
  },
  {
    label: 'Packages',
    component: dynamic(() => import('./Packages').then((mod) => mod.Packages)),
    route: 'packages',
  },
];

export const Dashboard = ({ originUrl, assetPrefix, packages, appConfig, mfes, title }) => {
  return (
    <Container>
      <DashboardContextProvider value={{ originUrl, assetPrefix, packages, appConfig, mfes }}>
        <TypeDisplayMedium gutter={{ top: '24px', bottom: '24px' }}>{title}</TypeDisplayMedium>
        <TabsContainer>
          <TabItems styleType="brand">
            {tabs.map(({ label }) => (
              <TabItem key={label}>{label}</TabItem>
            ))}
          </TabItems>
          <TabContents>
            {tabs.map(({ component: Component, label }) => (
              <TabContent key={label}>
                <Component />
              </TabContent>
            ))}
          </TabContents>
        </TabsContainer>
      </DashboardContextProvider>
    </Container>
  );
};

export default Dashboard;
