import { AdsConfig } from '@islands/components';
import { IslandContextProvider } from '@islands/context';
import { useTrackCWV } from '@islands/hooks';
import { setCustomAttribute } from '@islands/monitoring/src/newrelic/client';
import { getAppInitialProps, logger as logging, reportWebVitalsToNewRelic } from '@islands/utils';
import { AppContext } from 'next/app';
import { Layout } from '../components/Layout';
import appConfig from '../config';

const logger = (namespace: string) => logging(namespace, appConfig.logLevel);
function App({
  Component,
  pageProps = {},
  appShellProps = {},
}: {
  Component: any;
  pageProps: any;
  appShellProps: any;
}) {
  useTrackCWV();
  setCustomAttribute('globalHoldoutTrackId', pageProps.globalHoldoutTrackId);
  return (
    <IslandContextProvider config={appConfig}>
      <Layout appShellProps={appShellProps} logger={logger} statusCode={pageProps.statusCode}>
        <AdsConfig {...appShellProps} />
        <Component {...pageProps} />
      </Layout>
    </IslandContextProvider>
  );
}

App.getInitialProps = async (appContext: AppContext) =>
  await getAppInitialProps(appContext, appConfig, logger);

export const reportWebVitals = reportWebVitalsToNewRelic;

export default App;
