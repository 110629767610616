/*
 *  The function overrideBrowserLogger is used to override the console.error function
 *  To determine if an error is a first-party error. We need to have more than 2 window.location.origin
 *  this is because the function itself adds a window.location.origin to the stack trace
 */
function overrideBrowserLogger(nrSuppress3rdPartyErrors) {
  return `\
var originalFunction = console.error;\
console.error = function() {\
  var args = Array.prototype.slice.call(arguments);\
  var error = new Error();\
  var stack = error.stack || "";\
  var firstPartyError = (stack.split(window.location.origin).length - 1) > 2;\
  if (window.newrelic && window.newrelic.noticeError) {\
    try {\
      if (${nrSuppress3rdPartyErrors} && !firstPartyError) {\
        return originalFunction.apply(console, args);\
      }\
      window.newrelic.noticeError(new Error(args[0]), { reason: (JSON.stringify(stack) || args[1] || "-"), firstPartyError: firstPartyError });\
    } catch (e) {\
      if (Array.isArray(args) && args.length) {\
        window.newrelic.noticeError(new Error(args[0], { reason: "Catch: " + (args[1] || "-"), firstPartyError: firstPartyError }));\
      }\
    }\
  }\
  return originalFunction.apply(console, args);\
}\
`;
}

const setCustomAttribute = (key, value) => {
  if (typeof window !== 'undefined' && value) {
    window.newrelic?.setCustomAttribute(key, value);
  }
};

export { overrideBrowserLogger, setCustomAttribute };
