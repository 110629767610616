const loglevel = require('loglevel');
const prefix = require('loglevel-plugin-prefix');

prefix.reg(loglevel);

const logger = (namespace, level, prefixOptions, prefixOptout = true) => {
  const log = loglevel.getLogger(namespace);

  const logLevel = loglevel.levels[level] || loglevel.levels.DEBUG;

  // These level sets can be put in a common module, for example. loglevel will
  // apply them when the same getLogger constant is provided.
  log.setLevel(logLevel);

  if (prefixOptout) {
    prefix.apply(
      log,
      prefixOptions || {
        template: `[%t] %l (${namespace}):`,
        levelFormatter(logLevel) {
          return logLevel.toUpperCase();
        },
        timestampFormatter(date) {
          return date.toISOString();
        },
      },
    );
  }

  return log;
};

module.exports = {
  logger,
};
